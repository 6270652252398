import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bg from "../images/bg.jpg";
import { createGlobalStyle } from 'styled-components'
import font1 from '../fonts/Verlag-Bold.woff';
import font2 from '../fonts/Verlag-Black.woff';


const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Verlag';
    src: 
    url('${font1}') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Verlag-Black';
    src: 
    url('${font2}') format('woff');
  font-weight: bold;
  font-style: normal;
}
`

const IndexPage = () => (
  <>
  <GlobalStyle />
  <section className="wrapper" style={{
    background: `url(${bg})`,
  }}>
  <Layout>
    <SEO title="Lavazza" keywords={[`lavazza`, `kawa`, `coffe`]} />
  </Layout>
  </section>
  </>
)

export default IndexPage

